<script>
import { menuItems } from "./menu";
import i18n from "../i18n";

import {
  languageMethods,
  authComputed,
  languageComputed,
} from "@/state/helpers";
/**
 * Menu component
 */
export default {
  props: ["page"],
  data() {
    return {
      menuItems: menuItems,

      languages: [
        {
          flag: require("@/assets/images/flags/us.jpg"),
          language: "en",
          title: "English",
        },
        {
          flag: require("@/assets/images/flags/french.jpg"),
          language: "fr",
          title: "Français",
        },
      ],
    };
  },
  computed: {
    ...authComputed,
    ...languageComputed,
    userRole() {
      return this.loggedIn.user.role;
    },
    current_flag() {
      if (this.getLanguage == "fr") {
        return require("@/assets/images/flags/french.jpg");
      } else {
        return require("@/assets/images/flags/us.jpg");
      }
    },
    current_language() {
      if (this.getLanguage == "fr") {
        return "fr";
      } else {
        return "en";
      }
    },
  },
  methods: {
    ...languageMethods,
    setLanguage(locale) {
      this.changeLang(locale.language);
      i18n.locale = locale.language;
      localStorage.setItem("lang", locale.language);
    },
  },
};
</script>
<template>
  <div class="row" style="background: white">
    <div class="col-2">
      <a href="/">
        <span class="logo-lg">
          <img
            src="@/assets/images/nextrack_logo.svg"
            alt
            width="160"
            class="mt-2 mb-2 ml-4"
          />
        </span>
      </a>
    </div>
    <div class="col-10">
      <ul
        style="
          display: flex;
          flex-direction: row;
          list-style: none;
          margin-bottom: 0px;
          justify-content: end;
        "
      >
        <li style="min-height: 60px">
          <b-dropdown variant="white" right toggle-class="header-item">
            <template v-slot:button-content>
              <img
                class="mr-1"
                :src="require('@/assets/images/website.svg')"
                alt="Header Language"
                width="22"
              />
              <b>{{ current_language }}</b>
            </template>
            <b-dropdown-item
              class="notify-item"
              v-for="(entry, i) in languages"
              :key="`Lang${i}`"
              :value="entry"
              @click="setLanguage(entry)"
              :link-class="{ active: entry.language === current_language }"
            >
              <span class="align-middle">{{ entry.title }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </li>
        <li style="min-height: 60px" v-for="item in menuItems" :key="item.id">
          <div
            class="mt-2"
            style="display: inline-block; vertical-align: middle"
            v-if="!item.isTitle && !item.isLayout"
            v-show="item.permissions.includes(userRole)"
          >
            <router-link v-if="item.link == page" :to="item.link">
              <i
                style="vertical-align: sub"
                :class="`ml-4 bx ${item.icon} font-size-20 text-dark-red`"
                v-if="item.icon"
              ></i>
              <span
                style="vertical-align: sub"
                class="textMenu ml-1 font-size-14 font-weight-bold text-dark-red"
                >{{ $t(item.label) }}</span
              >
            </router-link>
            <router-link
              v-if="
                (item.link != page && item.link == '/') ||
                (item.link != page && item.link == '/transport') ||
                (item.link != page && item.link == '/health-centers') ||
                (item.link != page && item.link == '/poste-maroc') ||
                item.link == '/alerts' ||
                item.link == '/super-congelateur' ||
                item.link == '/consolidated' ||
                item.link == '/offline' ||
                item.link == '/interventions'
              "
              :to="item.link"
            >
              <i
                style="vertical-align: sub"
                :class="`ml-4 mt-1 bx ${item.icon} font-size-20 text-secondary`"
                v-if="item.icon && item.link != '/interventions'"
              ></i>
              <div
                :class="`ml-3 mt-2 font-size-17 font-weight-bold text-secondary`"
                v-if="item.link == '/interventions'"
              >
              4.0</div>
              <span
              v-if="item.link != '/interventions'"
                style="vertical-align: sub"
                class="textMenu ml-1 mt-1 font-size-14 font-weight-bold text-secondary"
                >{{ $t(item.label) }}</span
              >
            </router-link>
          </div>
        </li>
        <li>
          <div
            class="mt-2"
            style="display: inline-block; vertical-align: middle"
          >
            <router-link to="/devices">
              <i
                style="vertical-align: sub"
                :class="`ml-4 ri-more-line font-size-20 text-secondary`"
              ></i>
              <span
                style="vertical-align: sub"
                class="textMenu ml-1 mr-2 font-size-14 font-weight-bold text-secondary"
                >{{ $t("navbar.more.text") }}</span
              >
            </router-link>
          </div>
        </li>
        <li>
          <div
            class="mt-2"
            style="display: inline-block; vertical-align: middle"
          >
            <router-link to="/logout">
              <i
                style="vertical-align: sub"
                :class="`ml-4 ri-shut-down-line font-size-20 text-secondary`"
              ></i>
              <span
                style="vertical-align: sub"
                class="textMenu ml-1 mr-2 font-size-14 font-weight-bold text-secondary"
                >{{ $t("navbar.dropdown.logout") }}</span
              >
            </router-link>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<style scoped>
@media (min-width: 760px) and (max-width: 1602px) {
  .textMenu {
    display: none;
  }
}
</style>