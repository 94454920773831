var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row",staticStyle:{"background":"white"}},[_vm._m(0),_c('div',{staticClass:"col-10"},[_c('ul',{staticStyle:{"display":"flex","flex-direction":"row","list-style":"none","margin-bottom":"0px","justify-content":"end"}},[_c('li',{staticStyle:{"min-height":"60px"}},[_c('b-dropdown',{attrs:{"variant":"white","right":"","toggle-class":"header-item"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('img',{staticClass:"mr-1",attrs:{"src":require('@/assets/images/website.svg'),"alt":"Header Language","width":"22"}}),_c('b',[_vm._v(_vm._s(_vm.current_language))])]},proxy:true}])},_vm._l((_vm.languages),function(entry,i){return _c('b-dropdown-item',{key:`Lang${i}`,staticClass:"notify-item",attrs:{"value":entry,"link-class":{ active: entry.language === _vm.current_language }},on:{"click":function($event){return _vm.setLanguage(entry)}}},[_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(entry.title))])])}),1)],1),_vm._l((_vm.menuItems),function(item){return _c('li',{key:item.id,staticStyle:{"min-height":"60px"}},[(!item.isTitle && !item.isLayout)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.permissions.includes(_vm.userRole)),expression:"item.permissions.includes(userRole)"}],staticClass:"mt-2",staticStyle:{"display":"inline-block","vertical-align":"middle"}},[(item.link == _vm.page)?_c('router-link',{attrs:{"to":item.link}},[(item.icon)?_c('i',{class:`ml-4 bx ${item.icon} font-size-20 text-dark-red`,staticStyle:{"vertical-align":"sub"}}):_vm._e(),_c('span',{staticClass:"textMenu ml-1 font-size-14 font-weight-bold text-dark-red",staticStyle:{"vertical-align":"sub"}},[_vm._v(_vm._s(_vm.$t(item.label)))])]):_vm._e(),(
              (item.link != _vm.page && item.link == '/') ||
              (item.link != _vm.page && item.link == '/transport') ||
              (item.link != _vm.page && item.link == '/health-centers') ||
              (item.link != _vm.page && item.link == '/poste-maroc') ||
              item.link == '/alerts' ||
              item.link == '/super-congelateur' ||
              item.link == '/consolidated' ||
              item.link == '/offline' ||
              item.link == '/interventions'
            )?_c('router-link',{attrs:{"to":item.link}},[(item.icon && item.link != '/interventions')?_c('i',{class:`ml-4 mt-1 bx ${item.icon} font-size-20 text-secondary`,staticStyle:{"vertical-align":"sub"}}):_vm._e(),(item.link == '/interventions')?_c('div',{class:`ml-3 mt-2 font-size-17 font-weight-bold text-secondary`},[_vm._v(" 4.0")]):_vm._e(),(item.link != '/interventions')?_c('span',{staticClass:"textMenu ml-1 mt-1 font-size-14 font-weight-bold text-secondary",staticStyle:{"vertical-align":"sub"}},[_vm._v(_vm._s(_vm.$t(item.label)))]):_vm._e()]):_vm._e()],1):_vm._e()])}),_c('li',[_c('div',{staticClass:"mt-2",staticStyle:{"display":"inline-block","vertical-align":"middle"}},[_c('router-link',{attrs:{"to":"/devices"}},[_c('i',{class:`ml-4 ri-more-line font-size-20 text-secondary`,staticStyle:{"vertical-align":"sub"}}),_c('span',{staticClass:"textMenu ml-1 mr-2 font-size-14 font-weight-bold text-secondary",staticStyle:{"vertical-align":"sub"}},[_vm._v(_vm._s(_vm.$t("navbar.more.text")))])])],1)]),_c('li',[_c('div',{staticClass:"mt-2",staticStyle:{"display":"inline-block","vertical-align":"middle"}},[_c('router-link',{attrs:{"to":"/logout"}},[_c('i',{class:`ml-4 ri-shut-down-line font-size-20 text-secondary`,staticStyle:{"vertical-align":"sub"}}),_c('span',{staticClass:"textMenu ml-1 mr-2 font-size-14 font-weight-bold text-secondary",staticStyle:{"vertical-align":"sub"}},[_vm._v(_vm._s(_vm.$t("navbar.dropdown.logout")))])])],1)])],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-2"},[_c('a',{attrs:{"href":"/"}},[_c('span',{staticClass:"logo-lg"},[_c('img',{staticClass:"mt-2 mb-2 ml-4",attrs:{"src":require("@/assets/images/nextrack_logo.svg"),"alt":"","width":"160"}})])])])
}]

export { render, staticRenderFns }